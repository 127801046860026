<template>
    <!-- 交易记录 -->
    <div id="businessList" class="communal" ref="communal">
        <!-- 顶部固定 -->
        <div class="top-fixed" ref="topFixed">
            <div class="container">
                <el-row :gutter="24">
                    <el-col style="width:20%;position: relative;">
                        <span class="label">交易时间:</span>
                        <el-date-picker
                            v-model="form.dateTime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :editable="false"
                            style="width:70%;"
                        ></el-date-picker>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label">交易类型:</span>
                        <el-select
                            v-model="form.tag"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in tags"
                                :key="item.content"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label">订单状态:</span>
                        <el-select
                            v-model="form.orders"
                            clearable
                            placeholder="请选择"
                            style="width:70%;"
                        >
                            <el-option
                                v-for="item in order"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-col>
                    <el-col style="width:20%">
                        <span class="label" style="color:#fff">关键字查询:</span>
                        <el-input
                            style="width:70%;"
                            v-model="form.txt"
                            placeholder="请输入关键字查询"
                            @keyup.enter.native="onSubmit"
                        ></el-input>
                    </el-col>
                    <el-col style="width:20%" class="search-btn">
                        <div style="width:100%;display:inline-block;text-align: right;">
                            <el-button type="primary" plain @click="onSubmit">搜索</el-button>
                            <el-button type="primary" plain @click="exportLists(list)">导出</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <div class="table-box">
                        <div class="i-block table-item">总交易额:<span class="c06">{{sum_amount}}</span></div>
                        <div class="i-block table-item">支出:<span class="cf5">{{spend_amount}}</span></div>
                    </div>
                    <el-table
                        ref="refTable"
                        :data="list"
                        :header-row-class-name="'table_header'"
                        :empty-text="tableTip"
                        @row-click="tableRowClick"
                        style="width: 100%"
                        :height="contentHeight - 54"
                        @selection-change="handleSelectionChange"
                        v-loading="loading"
                    >>
                        <!-- 勾选 -->
                        <el-table-column
                            type="selection"
                            width="60">
                        </el-table-column>

                        <el-table-column prop="order_no" label="交易单号" width="200"></el-table-column>
                        <el-table-column prop="title" label="订单名称" width="150"></el-table-column>
                        <el-table-column prop="username" label="用户" width="150"></el-table-column>
                        <el-table-column prop="type_price" label="金额（元）" ></el-table-column>
                        <el-table-column prop="addtime" label="交易时间" width="130"></el-table-column>
                        <el-table-column prop="trader_type" label="类型" width="130"></el-table-column>
                        <el-table-column prop="order_status" label="订单状态" width="130"></el-table-column>
                        <el-table-column prop="trader_status" label="交易状态" width="130"></el-table-column>
                        <el-table-column prop="name" label="操作" fixed="right" width="200">
                            <template slot-scope="scope" v-if="scope.row.flag == true">
                                <template  v-if="scope.row.type == '1'">
                                    <el-button type="text" @click.stop="freezing(scope.row.id,scope.row.log_id,1)" v-if="scope.row.order_status == '正常'">冻结</el-button>
                                    <el-button type="text" @click.stop="freezing(scope.row.id,scope.row.log_id,2)" v-if="scope.row.order_status == '已冻结'">解冻</el-button>
                                    <el-button type="text" @click.stop="freezing(scope.row.id,scope.row.log_id,3)" v-if="scope.row.order_status == '已冻结' && scope.row.trader_status != '已退款'">退款</el-button>
                                    <el-button type="text" v-if="scope.row.order_status == '已取消'">-</el-button>
                                </template>
                                <template  v-else-if="scope.row.type == '2'">
                                    <el-button type="text" @click.stop="freezing(scope.row.id,scope.row.log_id,4)" v-if="scope.row.order_status =='待审核'">审核通过</el-button>
                                    <el-button type="text" v-if="scope.row.order_status =='审核通过'">-</el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="currentPage"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="psize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            ></el-pagination>
        </div>

        <!-- 弹框  start -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="480px" top="20%">
            <div style="padding:30px 60px 0">
                <div class="p-b-10 font-s14 c66">退款原因</div>
                <el-input
                    type="textarea"
                    rows="5"
                    v-model="refuseContent"
                    maxlength="200"
                    class="mb-20"
                    placeholder="请输入退款原因，20字以内"
                ></el-input>
                <span class="cf5 font-s12 i-block mb-20" style="margin-top:-20px">款项将原路返回，该操作不可逆谨慎操作</span>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                </div>
                <div>
                    <el-button type="primary" @click="refundTrader">确 定</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- 调用按钮操作性弹框 -->
        <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :parameter="parameter"
            :operationId='operationId'  
            :par="optionss"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import { TreaderType } from "../../api/api";
import { OrderStatusO  } from "../../api/api";
import { TransactionRecords  } from "../../api/api";
import { FreezTrader  } from "../../api/api";
import { UnFreezTrader  } from "../../api/api";
import { RefundTrader  } from "../../api/api";
import { ApprovalOrder  } from "../../api/api";
import { ExportTraders  } from "../../api/api";
import { constants } from 'crypto';
import operationBtn from "../../components/operationBtn";
export default {
    components: {
        operationBtn,
    },
    data() {
        return {
            topHeight: "", //顶部搜索的高度
            mainHeight: "", //获取当前容器的高度
            pagingHeight: "", //获取分页的高度
            contentHeight: 0, //内容的高度
            form:{
                dateTime:[],//日期
                tag:'',//症状标签
                orders:'',//订单状态
                txt:'',//文字
            },
            tags:[],//交易类型标签列表
            order:[],//订单状态
            list:[],//表格
            totals: null,
            pages: 1,
            psize: 10,
            currentPage: 1, //默认显示页
            tableTip:'系统正在检索中…',
            loading:false,
            refuseContent:'',//退款原因
            dialogVisible: false,//退款弹框
            selectUser:[],

            // 操作性按钮弹框值   start
            issue: "", //问题   上面的文字
            hintTxt: "", //提示文本   下方的文字
            showHide: false, //弹框的隐藏显示
            operationId: "", //操作id
            operationPsotUrl: "", //操作接口名字
            operationPsotName:"", //属性
            parameter:'',//参数
            // 操作性按钮弹框值   end

            sum_amount:0,//总交易额
            spend_amount:0,//支出金额
        };
    },
    created() {
        this.getTags();
        this.getOrder();
    },
    mounted() {
        var that = this;
        that.common.Count.pageResize(that);
        window.onresize = function temp() {
            that.common.Count.pageResize(that);
        };
        this.getUserList();
    },

    methods: {
        //获取交易列表
        getUserList() {
            var that = this;
            var db = {
                from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                trader_type: this.form.tag,
                order_status:this.form.orders,
                txt: this.form.txt,
                pnums: this.psize,
                pages: this.pages
            };
            this.loading = true;
            this.$axios.post(TransactionRecords, db).then(
                res => {
                    this.loading = false;
                    if (res.data.code == 1) {
                        if (res.data.data.list.length > 0) {
                            this.list = res.data.data.list;
                            this.totals = res.data.count;
                        } else {
                            this.list = [];
                            this.totals = 0;
                            this.tableTip = "暂无注册用户";
                        }
                        this.spend_amount=res.data.data.spend_amount
                        this.sum_amount=res.data.data.sum_amount
                    } else {
                        this.list = [];
                        this.totals = 0;
                        this.tableTip = "暂无注册用户";
                    }
                },
                err => {
                    this.loading = false;
                    this.list = [];
                    this.totals = 0;
                    this.tableTip = "暂无注册用户";
                }
            );
        },
        //获取交易类型
        getTags() {
            var that = this;

            this.$axios.post(TreaderType, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.tags = res.data.data;
                        } else {
                            this.tags = [];
                        }
                    } else {
                        this.tags = [];
                    }
                },
                err => {
                    this.tags = [];
                }
            );
        },
        //获取订单状态
        getOrder() {
            var that = this;

            this.$axios.post(OrderStatusO, {}).then(
                res => {
                    if (res.data.code == 1) {
                        if (
                            res.data.data != undefined &&
                            res.data.data.length > 0
                        ) {
                            this.order = res.data.data;
                        } else {
                            this.order = [];
                        }
                    } else {
                        this.order = [];
                    }
                },
                err => {
                    this.order = [];
                }
            );
        },
        //点击表格行
        tableRowClick(row, index, e) {
            this.$refs.refTable.toggleRowExpansion(row);
        },
        //查看跳转
        skipInfo(id) {
            this.$router.push({
                path: "viewInfo"
                // query: { id: id}
            });
        },
        //冻结，解冻，退款，通过提现审核
        freezing(id, log_id,type) {
            if(type != 3){
                this.showHide = !this.showHide;
            }
            this.parameter = {
                id:id,
                log_id:log_id
            };
            if(type == 1) {
                //冻结
                this.issue = '确定解冻该交易？';
                this.hintTxt =  "冻结后该交易不可提现";
                this.operationPsotUrl = FreezTrader;
                this.operationPsotUrl = FreezTrader;
                this.operationPsotName = 'id';
                this.operationId=id
            } else if (type == 2) {
                //解冻
                this.issue = '确定冻解冻该交易？';
                this.hintTxt =  "解冻后该交易过约定期限后可提现";
                this.operationPsotUrl = UnFreezTrader;
                this.operationPsotName = 'id';
                this.operationId=id
            } else if(type == 3) { 
                //退款
                this.dialogVisible = !this.dialogVisible;
            } else if (type == 4) { 
                //通过提现审核
                this.issue = '确定通过该提现审核？';
                this.hintTxt =  "审核成功后系统将扣除平台费用自动打款给用户";
                this.operationPsotUrl = ApprovalOrder;
            }
        },
        //退款弹框点击确定
        refundTrader(){            
            var db = this.parameter;  
            db.refund_message = this.refuseContent;
            if (this.refuseContent.length == 0 || this.refuseContent.length > 20) {
                this.$message.error('请输入0-20个字');
            } else {
                this.$axios.post(RefundTrader, db).then(
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                        this.dialogVisible = !this.dialogVisible;
                        this.refuseContent = '';
                        this.getUserList();
                    },
                    err => {}
                );
            };
        },
        //弹框返回
        childByOperationBtn: function(showHide, dataCode) {
            //接收按钮式操作子组件传值
            this.showHide = showHide;
            if (dataCode == 1) {
                //接口调用成功，调用其他接口
                this.getUserList();
            }
        },
        //搜索
        onSubmit() {
            this.pages = 1;
            this.getUserList();
        },
        //被勾选中的用户
        handleSelectionChange(val) {
            this.selectUser = val;
        },
        //导出
        exportLists() {
            var arr = this.selectUser;
            var ids = [];
            if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    ids.push(arr[i].log_id);
                }

                var db = {
                    log_ids: ids.join(","),
                    from_date:
                    this.form.dateTime != null ? this.form.dateTime[0] : "",
                to_date:
                    this.form.dateTime != null ? this.form.dateTime[1] : "",
                };
                this.$axios
                    .post(ExportTraders, db, {
                        responseType: "arraybuffer"
                    })
                    .then(
                        res => {
                            let blob = new Blob([res.data], {
                                type: "application/vnd.ms-excel"
                            });
                            const fileName =res.headers["content-disposition"].match(/filename=(\S*).xls/)[1] ; 
                            const elink =document.createElement('a');
                            elink.download = JSON.parse(fileName)+".xls";
                            elink.href = window.URL.createObjectURL(blob);
                            elink.click();
                            window.URL.revokeObjectURL(elink.href);
                        },
                        err => {}
                    );
            } else {
                this.$message.info("至少选择一个交易进行导出");
            }
        },
        handleCurrentChange(val) {
            this.pages = val;
            this.getUserList();
        },
        handleSizeChange(val) {
            this.psize = val;
            this.getUserList();
        }
    }
};
</script>
<style lang="scss">
.label {
    font-size: 14px;
    color: #666666;
    margin-right: 10px;
}
.search-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.table {
    height: calc(100vh - 167px - 100px - 40px - 20px);
    .props-rows {
        padding: 10px 0px 10px 45px;
        .title {
            color: #999999;
            margin-right: 20px;
        }
    }
}
.rows-tag {
    color: #666666;
    margin-right: 20px;
}
.table-item{
    font-size: 18px;
    color: #666666;
    letter-spacing: 0;
    padding: 20px 30px 10px 30px;
    span{
        margin-left: 10px;
    }
}
</style>
